import logo from './logo2.png'
import './App.css'
import { useState, useEffect, useCallback } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const BACKEND_URL = "https://onetime.networkthinking.com/api/password"


const MainApp = () => {
  const [url, setUrl] = useState(null);
  const [password, setPassword] = useState(null);
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.target)
    const message = data.get('message')
    const lifetime = parseInt(data.get('lifetime'))
    setPassword(message)  
    const response = await fetch(BACKEND_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({"message": message, "lifetime": lifetime})
    })
    const result = await response.json()
    const uuid = result.uuid
    setUrl(window.location.protocol + '//' + window.location.host + '/' + uuid)
  }
  const ShowLink = ({url}) => {
    return (
      <Form className='my-5'>
        <Form.Text className="text-muted">
          Here is the link:
        </Form.Text>
        <Form.Group className="mb-5" controlId="groupMessage">
          <Form.Control value={url} disabled />
        </Form.Group>
        <Form.Text className="text-muted">
          Original Password:
        </Form.Text>
        <Form.Group controlId="groupMessage">
          <Form.Control value={password} disabled/>
        </Form.Group>
      </Form>
    )
  }
  const MainForm = () => {
    return (
    <Form className='my-5' onSubmit={handleSubmit}>
      <Form.Group className="mb-5" controlId="groupMessage">
        <FloatingLabel controlId="floatingTextarea2" label="Paste password or secret message below">
          <Form.Control
            as="textarea"
            placeholder="Paste password or secret message here"
            style={{ height: '200px' }}
            name="message"
          />
        </FloatingLabel>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="groupOption">
        <Form.Label column sm={2}>Lifetime</Form.Label>
        <Col sm={10}>
          <Form.Select aria-label="Default select example" name="lifetime">
            <option value="60">1 hour (Default)</option>
            <option value="480">8 hours</option>
            <option value="1440">1 day</option>
            <option value="10080">7 day</option>
          </Form.Select>
        </Col>
      </Form.Group>

      <Form.Group className='mb-3' controlId="groupText">
        <Form.Text className="text-muted">
            A secret link only works once and then disappears forever.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="groupSubmit">
        <Button variant="primary" type="submit" className="my-5">
          Create a secret link
        </Button>
      </Form.Group>
    </Form>
  )
  }


  if(url == null) {
    return <MainForm />
  } else {
    return <ShowLink url={url} />
  }

}


const RevealSecret = ({uuid}) => {
  const [secret, setSecret] = useState(null)
  const [ready, setReady] = useState(false)

  const checkMessage = useCallback(async() => {
    const response = await fetch(`${BACKEND_URL}?uuid=${uuid}`)
      if(response.status === 404) {
        // setSecret('This secret does not exist or expired.')
      } else {
        // setSecret('Ready to reveal')
        setReady(true)
      }
  }, [uuid]);

  const revealMessage = async() => {
    setReady(false)
    const response = await fetch(`${BACKEND_URL}?uuid=${uuid}`, {method: 'DELETE'})
    if(response.status === 404) {
      // setSecret('This secret does not exist or expired.')
    } else {
      const result = await response.json()
      setSecret(result.message)
    }
  }

  useEffect(() => {
    checkMessage()
  }, [checkMessage])

  return (
    <Form className='my-5'>
      <Form.Group className="mb-3" controlId="message">
          <Form.Control
            as="textarea"
            value={secret ? secret : ready ? 'Ready...' : ''}
            style={{ height: '200px' }}
            disabled
          />
      </Form.Group>

      <Form.Group className="mb-5" controlId="button">
        <Form.Text className="text-muted">
          {secret === null && ready === false && ('This secret does not exist or expired')}
          {secret === null && ready === true && ('Reveal will only show once!')}
          {secret != null && ('This secret have been destroyed already. Please make a copy immediately!')}
        </Form.Text>
      </Form.Group>

      { ready && (
      <Form.Group className="mb-5" controlId="button">
        <Button onClick={revealMessage}>Reveal!</Button>
      </Form.Group>
      )}
    </Form>


  )
}

const App = () => {
  return (
    <div className="App d-flex justify-content-center">
      <header className="App-header">
        <img src={logo} className="App-logo rounded" alt="logo" />
        { window.location.pathname === '/' && <MainApp />
        }
        {  window.location.pathname !== '/' && <RevealSecret uuid={window.location.pathname.slice(1)} />
        }
        <div className='text-muted fixed-bottom'>
          <small>© 2023 – Network Thinking Solutions. All Rights Reserved.</small>
        </div>
      </header>
    </div>
  )
 
}

export default App
